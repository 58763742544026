import { SIGN_IN, SIGN_OUT } from './types'

const INTIAL_STATE = {
  isSignedIn: null,
  userId: null,
}

const authReducer = (state = INTIAL_STATE, action) => {
  switch (action.type) {
    case SIGN_IN:
      return {
        ...state,
        isSignedIn: true,
        userId: action.payload.userId,
        googleEmail: action.payload.googleEmail,
      }
    case SIGN_OUT:
      return { ...state, isSignedIn: false, userId: null }
    default:
      return state
  }
}
export default authReducer
